.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 4px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 28px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.ant-table-cell.ant-table-column-sort {
  background: #cdd9e0;
}

.mobile-none {
  @media (max-width: 768px) {
    display: none;
  }
}

.desktop-none {
  @media (min-width: 769px) {
    display: none;
  }
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  height: 64px;
  font-size: 20px;
  padding-top: 10px;
  z-index: 99;
}
